import React from "react"
import { useForm } from "react-hook-form"

export default function NewsletterForm({ status, message, onValidated }) {
  const { register, handleSubmit } = useForm()
  const submit = data =>
    data.email &&
    data.email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: data.email,
    })
  return (
    <div className="mt-8 flex flex-col md:flex-row sm:items-center lg:flex-shrink-0">
      <div className="">
        <input
          ref={register}
          name="email"
          type="text"
          className="bg-white rounded-md border border-gray-400 focus:outline-none focus:border-olive-500 text-base px-4 py-3 md:mr-3"
          placeholder="Enter your email"
        />
      </div>
      <div className="rounded-md mt-5 md:mt-0">
        {status === "sending" ? (
          <button
            onClick={handleSubmit(submit)}
            className="disabled shadow items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-olive-500 hover:bg-olive-400 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            ...Sending
          </button>
        ) : (
          <button
            onClick={handleSubmit(submit)}
            className="shadow items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-olive-600 hover:bg-olive-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            Join mailing list
          </button>
        )}
      </div>
    </div>
  )
}
