/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import Header from "./Header"
import Footer from "./Footer"
import "../styles.css"

import "fontsource-work-sans"
import "fontsource-work-sans/700.css"
import "fontsource-playfair-display"
import "fontsource-playfair-display/900.css"
import "fontsource-playfair-display/700-normal.css"

const Layout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const location = useLocation()
  return (
    <>
      {location.pathname !== "/" && (
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      )}
      {page === "home" ? (
        <main className="mx-auto">{children}</main>
      ) : (
        <main className="container mx-auto">{children}</main>
      )}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
